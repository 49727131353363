const specializationsCards = document.querySelectorAll(
  ".specializations__card"
);

if (specializationsCards) {
  specializationsCards.forEach((card) => {
    const quarters = [];
    const children = card.childNodes;

    children.forEach((el) => {
      if (el.nodeName === "SPAN") {
        quarters.push(el);
      }
    });

    quarters.forEach((quarter, index) => {
      quarter.addEventListener("mouseover", () => {
        card.className = `specializations__card specializations__card--animation${index +
          1}`;
      });
    });
  });
}
